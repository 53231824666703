@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

.name {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 0;
}

.position {
    display: flex;

    align-items: center;

    * + * {
        margin-left: 13px;
    }
}

.position-text {
    font-size: 16px;
    color: $color-primary;
    font-weight: 500;
    margin-bottom: 0;
}

.email {
    color: rgba(0, 0, 0, 0.50);
    transition: .2s;
    cursor: pointer;
    font-size: 12px;

    &:hover {
        color: #000
    }
}

.person {
    position: relative;

    &__info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 4px;
    }

    & p {
        margin: 0;
        word-wrap: break-word;
        line-height: 120%
    }
}

.description {
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
}

$popoverWidth: 250px;

.popover {
    background-color: transparent;
    border: none;

    &__inner {
        background-color: $color-off-white;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid #20836D80;
        width: $popoverWidth;
        margin-left: auto;
    }
} 

.photo {
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;

    & img, & div {
        transition: .3s;
        border: 1px solid transparent;
    }

    &__active > img, &__active > div {
        border: 1px solid $color-primary-light;
    }
}