@import "../../styles/tools";

.tooltip-container {
  box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, 0.1);

  :global(.tippy-content) {
    font-weight: 400;
    text-align: left;
    font-size: 14px;
    line-height: 16px;

    ol,
    ul {
      margin: 0;

      &:not(:first-child) {
        margin-top: 7px;
      }

      &:not(:last-child) {
        margin-bottom: 7px;
      }
    }

    p,
    ol > li,
    ul > li {
      font-weight: 400;
      color: $color-off-white;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.wrapper:not(:first-child) {
  .icon {
    margin-left: 8px;
  }
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  // background: url('../../img/question-icon.svg');
  cursor: help;
  line-height: 0;
  vertical-align: middle;

  &:hover {
    // background: url('../../img/question-icon--hover.svg');
  }
}

.full-width {
  width: 100%;
}
