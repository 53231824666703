@import "~@ui/styles/tools";

.wrapper {
    max-width: 472px;
}

.icon-wrapper {
    position: relative;
    background: linear-gradient(129.17deg, #1F7A66 2.27%, #274B43 102.78%);
    border-radius: 24px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > img {
        object-fit: cover;
    }
}

.icon {
    width: 152px;
    height: 176px;
    position: relative;
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.36px;
    text-align: left;
    color: #fff;
    margin-bottom: 30px;
    max-height: 55px;
}

.text {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.36px;
    text-align: left;
    margin-bottom: 20px;
}

.text-white {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    text-align: center;
    max-width: 70%;
    color: #fff;
    max-height: 60px;
    overflow: hidden;
}

.text-small {
    font-size: 14px;
    font-weight: 300;
    line-height: 19.6px;
    letter-spacing: 0.36px;
    text-align: center;
    color: #fff;
    max-height: 45px;
    overflow: hidden;
}

.buttons {
    text-align: center;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.button {
    height: 44px;
}

.button-container {
    margin-right: 4px !important;
}

.logo {
    margin-top: 20px;
    position: relative;
    width: 58px;
    height: 14px;
}

.modal-content {
    padding: 0;
    padding-bottom: 20px;
}

.animated-wrapper {
    position: relative;
    height: 62vh;
    min-height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
}

.animated-item {
    position: absolute;
    width: 90%;
    height: 100%;
    left: 20px;
}

@include media-mobile {
    .icon-wrapper {
        padding: 20px;
    }
    
    .icon {
        width: 140px;
        height: 150px;
    }
    
    .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 20px;
    }
    
    .text {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 15px;
    }
    
    .text-white {
        margin-top: 15px;
        font-size: 14px;
        line-height: 20.4px;
    }
    
    .text-small {
        font-size: 12px;
        line-height: 17.6px;
    }

    .animated-wrapper {
        height: 100%;
        max-height: auto;
    }
}