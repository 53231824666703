@import "../../styles/tools";

.body-overflow {
    overflow: hidden;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100vw;
    height: 100vh;
    background: rgba($color-title-active, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    transition: opacity 225ms ease-in-out;
}

.overlay--after-open {
    opacity: 1;
}

.overlay--before-close {
    opacity: 0;
}

.container {
    width: 100%;
    max-width: 720px;
    margin: auto;
    display: flex;
    flex-direction: column;
    background: $color-off-white;
    border-radius: $border-radius;
    outline: none;

    transform: translateY(-25vh);
    transition: transform 225ms ease-in-out;
}

.container--after-open {
    transform: translateY(0);
}

.container--before-close {
    transform: translateY(-25vh);
}

.container--xsmall {
    max-width: 410px;

    @include media-mobile {
        max-width: unset;
    }
}

.container--small {
    max-width: 600px;
}

.container--medium {
    max-width: 910px;
}

.container--wide {
    max-width: 1200px;
}

.container--fullscreen {
    max-width: 100vw;
    height: 100vh;
    border-radius: 0;
    border: none;

    .form-wrapper, .wrapper {
        max-height: 100vh;
    }
}

.container--fit {
    max-width: fit-content;

    @include media-mobile {
        max-width: unset;
    }
}

.form-wrapper,
.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 85vh;
}

.header {
    padding: 24px 30px;
    display: flex;
}

.header--border {
    border-bottom: 1px solid #E9E9E9;
}

.header--primary {
    display: flex;
    flex: 1;
    align-items: flex-start;
}

@include media-mobile {
    .header {
        flex-direction: column;
    }
}

.header__title {
    color: $color-title-active;
    font-weight: 600;
    font-size: $font-size--xlarge;
    line-height: 28px;
    vertical-align: middle;
    flex: 1;
    margin: 0;
    align-self: center;
}

.header__custom {
    
}

.header__close-button-wrapper {
    height: 28px;
    margin-top: -4px;
    margin-right: -24px;
}

.content {
    // Don't use there `flex: 1` to prevent issues in Safari and collapsing it to 0px height block.
    // flex: 1;
    padding: 24px 30px;
    overflow: auto;
}

.footer {
    padding: 24px 30px;
}

.footer--border {
   border-top: 1px solid #E9E9E9; 
}

// Fix for dadata input
.container {
    :global(.suggestions-wrapper) {
        position: inherit;
        z-index: 2020;
    }

    :global(.suggestions-suggestions) {
        left: inherit;
        min-width: 450px;
        max-width: 450px;
    }
}

.header--empty {
    padding: 0 30px;

    .header__close-button-wrapper {
        margin-top: 4px;
        margin-bottom: 4px;
    }
}

.header--reduced-space {
    padding-bottom: 0;
}

.content--empty {
    padding-top: 0;
}

@include media-mobile {
    .container:not(.container--squeezed-mobile) {
        position: absolute;
        left: 0;
        top: 0;
        height: 100vh;
        max-height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        max-height: calc(var(--vh, 1vh) * 100);
        border-radius: 0;
        border: none;

        // Fix for dadata 
        :global(.suggestions-suggestions) {
            left: 10px;
            min-width: calc(100vw - 20px);
            max-width: calc(100vw - 20px);
        }
    }

    .form-wrapper,
    .wrapper:not(.wrapper--squeezed-mobile) {
        height: 100vh;
        max-height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        max-height: calc(var(--vh, 1vh) * 100);
    }

    .header {
        padding: 12px 15px;
    }

    .header--empty {
        padding: 0 15px;
    }
    
    .footer {
        padding: 12px 15px;
        text-align: center;
    }

    .content {
        padding: 15px;
    }

    .content--empty {
        padding-top: 0;
    }

    .header__close-button-wrapper {
        margin-right: -15px;
    }
}