@import "../../../styles/tools";

//$animation-duration: 0.178s;
$animation-duration: 0.28s;

.wrapper {
    display: block;
    margin-bottom: 20px;
}

.wrapper--no-margin-bottom {
    margin-bottom: 0;
}

.container {
    border: 1px solid $color-input-background;
    background: $color-background;
    box-sizing: border-box;
    border-radius: $border-radius;
    overflow: hidden;
    cursor: text;
    height: 54px;
    display: flex;
    align-items: center;

    transition: all $animation-duration ease-in-out;

    @include theme-dark {
        background-color: #262828;
        border-color: #818181;
    }
}

.gap {
    gap: 10px;
}

.container--white {
    background: $color-off-white;
}

.container--last {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.container--small {
    padding: 5px 10px;
}

.error {
    font-size: $font-size--xsmall;
    line-height: $font-size--xsmall;
    font-weight: 300;
    letter-spacing: 0.3px;
    display: block;
    padding: 5px 10px;
    color: $color-error;
}

.footer {
    padding: 5px 10px;
    font-size: $font-size--xsmall;
    line-height: $font-size--xsmall;
    font-weight: 300;
    letter-spacing: 0.3px;
}

.error + .footer {
    padding-top: 0;
}

.container--focused {
    border-color: $color-primary;

    @include theme-dark {
        border-color: $color-primary;
    }
}

.container--last--focused {
    //justify-content: space-between;
}

.container--placeholder {
    // justify-content: space-between;
    color: $color-grey-dark;
}

.input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    font-size: $font-size--small;
    line-height: $font-size--small;
    margin-bottom: -25px;
    display: block;
    color: $color-grey-dark;
    font-weight: 300;
    padding: 0 14px;
    padding-top: 10px;

    transition: all $animation-duration ease-in-out;
}

.label__text {
    color: $color-grey-dark;
    transition: all $animation-duration ease-in-out;
    height: 16px;
    line-height: 16px;
}

.img-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 14px;
    padding-right: 0;
}

.container--error {
    border-color: $color-error;
    background: $color-background-error;

    .label__text {
        color: $color-error;
    }

    .input, input.input, input[type].input {
        &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
            -webkit-box-shadow: inset 0 0 0 50px $color-background-error;

            @include theme-dark {
                -webkit-box-shadow: inset 0 0 0 50px #262828 !important;
            }
        }
    }
}

.input, input.input, input[type].input {
    display: block;
    border: 0;
    background: transparent;
    font-size: $font-size--small;
    line-height: $font-size--small;
    font-weight: 300;
    color: $color-title-active;
    padding: 10px 14px;
    padding-top: 6px;
    padding-bottom: 9px;
    width: 100%;
    border-radius: $border-radius;

    transition: all $animation-duration ease-in-out;

    @include theme-dark {
        color: #FFF;
    }

    &:focus {
        outline-width: 0;
        outline: none !important;
    }

    &::placeholder {
        color: $color-grey-dark;
    }
    
    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
        -webkit-box-shadow: inset 0 0 0 50px $color-background;
        transition: all $animation-duration ease-in-out;
    }
}

.input__postfix {
    line-height: 18px;
    font-size: 18px;
    color: $color-grey-dark;
    font-weight: 500;
    padding-right: 14px;

    transition: all $animation-duration ease-in-out;
}

.container--focused, .container--has-value {

    .input {
        transform: translateY(10px);
    }

    .label {
        transform: translateY(-12px);
    }

    .label__text {
        font-size: 10px;
        display: block;
        color: $color-grey-dark;
        font-weight: 300;
    }
}

.container--disabled {
    // background: #F5F5F5;
    // color: #5B5C5B;
    opacity: 0.3;
    cursor: default;

    .input, input.input, input[type].input {
        color: #5B5C5B;
    }
}

// Dadata integration
.input + :global(.suggestions-wrapper) {
    transform: translate(0, 20px);
    z-index: 10;
}