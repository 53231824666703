@import "../../styles/tools";

$breadcrumb-color-active: #fff;
$breadcrumb-color-background: $color-orange;
$breadcrumb-color-background-completed: #00AA85;
$breadcrumb-color-foreground: #7C7B7C;
$breadcrumb-color-foreground-completed: #fff;
$breadcrumb-color-foreground-default: #000c25;
$breadcrumb-color-foreground-active: $breadcrumb-color-active;
$breadcrumb-color-border-default: #E9E9E9;
$breadcrumb-color-border-active: $breadcrumb-color-active;
$breadcrumb-color-border-separator: #e2e8ef;

$wizard-color-background: #fff;

$wizard-size-border-radius: 5px;

.wizard {
    &__bredcrumb_button {
        counter-increment: wizard;

        &::before {
            background-color: transparent;
            border: 1px solid $breadcrumb-color-border-default;
            border-radius: 50%;
            content: counter(wizard);
            display: inline-block;
            font-size: 16px;
            height: 32px;
            line-height: 33px;
            margin-right: 12px;
            text-align: center;
            width: 32px;
            font-weight: 500;
        }

        &__title {
            border-bottom: 1px dashed $breadcrumb-color-foreground;
        }
    }

    &--active {
        color: $breadcrumb-color-foreground-default;
        font-weight: 600;

        &::before {
            border-color: $breadcrumb-color-border-active;
            box-shadow: inset 0 0 0 1px $breadcrumb-color-border-active;
            color: $breadcrumb-color-foreground-active;
            font-weight: 500;
        }

        &__title {
            border-bottom: 1px dashed $breadcrumb-color-foreground-default;
        }
    }

    &--current {
        color: $breadcrumb-color-foreground-default;
        font-weight: 600;

        &::before {
            background-color: $breadcrumb-color-background;
            border-color: $breadcrumb-color-border-active;
            box-shadow: inset 0 0 0 1px $breadcrumb-color-border-active;
            color: $breadcrumb-color-foreground-active;
            font-weight: 500;
        }

        &__title {
            border-bottom: 1px dashed $breadcrumb-color-foreground-default;
        }
    }

    &--completed {
        color: $color-green;

        &::before {
            background-color: $breadcrumb-color-background-completed;
            background: url('./icon-checkmark.svg') no-repeat center, $breadcrumb-color-background-completed;
            background-size: 16px;
            border-color: transparent;
            color: transparent;
            font-weight: 500;
        }

        &__title {
            border-bottom: 1px dashed $color-green;
        }
    }

    a {
        text-decoration: none;
    }
}
