@import "../../../styles/tools";

.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    width: 100%;
}

.content {
    display: flex;
    justify-content: space-between;
    background: $color-background;
    border: 1px solid $color-input-background;
    border-bottom: none;
    border-radius: 12px;
    padding: 20px 12px;
}

.content--white {
    background: $color-off-white;
}

.content--without-border {
    border: none;
    padding-left: 0;
    padding-right: 0;
}

.title {
    color: $color-title-active;
    font-size: $font-size--normal;
    line-height: $font-size--normal;
    font-weight: 300;
}

.label {
    color: $color-title-active;
    font-size: $font-size--small;
    line-height: $font-size--small;
    font-weight: 300;
}

.range {
    margin: 0 12px;
    height: 1px;
    background: $color-input-background;
    border-radius: 2px;
}

.range--without-border {
    margin: 0;
}

.range__area {
    position: relative;
    background: $color-primary;
    height: 1px;
    margin-bottom: -3px;
    border-radius: 2px;
}

.knobs-container {
    margin: 0 12px; 
    height: 12px;
}

.knobs-container--without-border {
    margin: 0;
}

.edge-labels-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
}

.edge-label {
    font-size: 14px;
    line-height: 14px;
    color: $color-title-active;
    opacity: .5;
    font-weight: 400;
}

.edge-label__left {
    text-align: left;
}

.edge-label__right {
    text-align: right;
}

.knob {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 20px;
    width: 20px;
    transform: translate(0px, -10px);
    margin-left: -9px;
    margin-right: -11px;

    .knob__button {
        content: ' ';
        width: 18px;
        height: 18px;
        background: $color-off-white;
        border: 4px solid $color-primary;
        border-radius: 50%;
        cursor: grabbing;

        @include media-mobile-up {
            &:hover {
                &::before {
                    content: ' ';
                    display: block;
                    border-radius: 50%;
                    transform: translate(-8px, -8px);
                    width: 26px;
                    height: 26px;
                    background: $color-primary;
                    opacity: 0.1;
                }
            }
        }

        @include media-mobile {
            &::before {
                content: ' ';
                display: block;
                border-radius: 50%;
                transform: translate(-8px, -8px);
                width: 26px;
                height: 26px;
                background: $color-green;
                opacity: 0.2;
            }
        }
    }

    &--active {
        .knob__button {
            cursor: grab;
        }
    }

    .knob__label {
        font-size: 16px;
        color: #000;
        text-align: center;
        transform: translateX(-50%);
        width: auto;
        margin-top: -4px;
    }
}

.editor__button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 2px 10px;
    margin: -2px 0;

    &:hover {
        background: rgba(0, 0, 0, 0.12);
    }
}

.bottom-warning-text {
    color: $color-error;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
}

.content {
    height: 60px;

    .editor__input {
        width: auto;
        font-size: 18px;
        font-weight: normal;
        line-height: 24px;
        margin-top: -10px;
        margin-bottom: -12px;
    }

    .editor__input-wrapper {
        height: 32px;
        margin-top: -30px;
    }

    &.content--without-spacing {
        .editor__button {
            padding: 0;
        }
    }
}

.container--type-danger {
    .knob__button {
        border-color: $color-error;
    }

    .range__area {
        background-color: $color-error;
    }
}

.container--disabled {
    pointer-events: none;

    .label:not(.label--non-disabled) {
        color: $color-grey--dark;
    }

    .knob__button {
        border-color: $color-input-background;
    }

    .range__area {
        background-color: $color-input-background;
    }
}

.container--size-large {
    .content {
        height: auto;
        border: none;
        background: none;
        align-items: center;
        padding: 23px 30px;
    }

    .label {
        font-size: 30px;
        line-height: 1em;
        font-weight: 400;
    }

    .title {
        margin-top: 10px;
        font-size: 18px;
        line-height: 1em;
        color: #5B5C5B;
    }

    .editor__input-wrapper {
        height: auto;
        margin-top: -49px;
    }
}

.value-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.value-title {
    margin-bottom: 5px;

    &--editable {
        padding-left: 10px;
    }
}

@include media-mobile {
    .container {
        padding: 0 8px;
        margin-bottom: 20px;
    }

    .title {
        font-size: 14px;
    }

    .label {
        font-size: 12px;
    }
}

@include theme-dark {
    .content {
        background: #222323;
        border-color: #64696C;
    }

    .knob__button {
        border-color: #20836D;
    }

    .label {
        color: #FFF;
        font-weight: 100;
    }

    .range {
        background: #64696C;
    }

    .range__area {
        background: #20836D;
    }
}