@import "~@ui/styles/tools";

.table-container {
    overflow-x: auto;
    margin-bottom: 30px;
}

.table {
    width: 100%;
    border: 1px solid #EBEEEF;
    border-radius: 12px;
    width: 100%;
    overflow-x: scroll;
}

.ceil-title {
    color: $color-grey;
    font-size: $font-size--small - 1;
    font-weight: 400;
    padding: 12px 6px;
    border-bottom: 1px solid #EBEEEF;
    border-right: 1px solid #EBEEEF;
    transition: 0.5s ease;

    &:last-child {
        border-right: none;
    }

    &--active {
        color: $color-primary;
        background: #FAFAFA;
        transition: 0.5s ease;
    }
}

.ceil {
    color: #27272A;
    font-size: $font-size--xsmall;
    font-weight: 500;
    padding: 12px 6px;
    border-bottom: 1px solid #EBEEEF;
    border-right: 1px solid #EBEEEF;
    transition: 0.5s ease;
    white-space: nowrap;

    &:last-child {
        border-right: none;
    }

    &--last {
        border-bottom: none;
    }

    &--active {
        color: $color-primary;
        background: #FAFAFA;
        transition: 0.5s ease;
    }

    @include media-mobile {
        font-size: $font-size--xsmall - 2;
    }
}