@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.wrapper {
    display: block;
    padding: 0 0 4%;
}

.directors-content {
    display: block;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        right: -2px;
        top: 0;
        bottom: 0;
        width: 100px;
        background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
        pointer-events: none;
        transform: translateX(100%);
        transition: 0.15s transform ease-in;
    }

    &::before {
        position: absolute;
        content: '';
        left: -2px;
        top: 0;
        bottom: 0;
        width: 100px;
        background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 2;
        pointer-events: none;
        transform: translateX(-100%);
        transition: 0.15s transform ease-in;
    }

    &--right-gradient {
        &::after {
            transform: translateX(0);
        }
    }

    &--left-gradient {
        &::before {
            transform: translateX(0);
        }
    }
}

.container {
    overflow-x: scroll;
    scrollbar-width: none;
    width: 100%;
    
    &::-webkit-scrollbar {
        display: none;
    }

    p {
        word-wrap: break-word;
        line-height: 120%;
    }
}

.title {
    font-size: $font-size-title;
    color: #1c2723;
    font-weight: 600;

    margin-bottom: 48px;
}

.cards {
    height: 346px;
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    column-gap: 12px;
}

@include medium-breakpoint {
    .wrapper {
        padding: 4% 0 4%;
    }

    .title {
        font-size: 40px;
    }
}

@include small-breakpoint {
    .wrapper {
        padding: 0% 0 4%;
    }

    .title {
        font-size: 32px;
    }
}

@include media-tablet {
    .title {
        font-size: 24px;
        margin-bottom: 32px;
    }
}

@include media-tablet-portrait {
    .cards {
        height: 272px;
    }
}

@include media-mobile {
    .title {
        font-size: 26px;
    }

    .cards {
        height: 294px;

        column-gap: 8px;
    }
}
