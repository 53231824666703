@import "../../../styles/tools";

.container {
    box-shadow: 0px 0px 2px rgba(160, 143, 143, 0.32);
    border-radius: 16px;
    padding: 13px 17px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;

    @mixin backgroundColor($name, $color) {
        &--background-#{$name} {
            background: rgba($color, 0.2);
            border: 1px solid $color;
        }
    }

    @include backgroundColor('success', $color-success);
    @include backgroundColor('warning', $color-warning);
    @include backgroundColor('error', $color-error);
    @include backgroundColor('info', $color-grey--light);
}

.image {
    &--start {
        align-self: start;
    }

    &--center {
        align-self: center;
    }

    &--end {
        align-self: end;
    }
}

.text {
    color: $color-title-active;
    letter-spacing: 0.3px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;

    @include theme-dark {
        color: #FFF;
    }
}

.container {
    @mixin fontColor($name, $color) {
        &--type-#{$name} {
            color: $color;
        }
    }

    @include fontColor('success', $color-primary);
    @include fontColor('warning', $color-warning2);
    @include fontColor('error', $color-error);
    @include fontColor('info', $color-title-active);
}