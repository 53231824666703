@use "sass:selector";

@mixin theme-dark {
    @if & != null {
        @at-root #{selector.nest(':global(body.jl-theme--dark)', &)} {
            @content;
        }
    } @else {
        @at-root :global(body.jl-theme--dark) {
            @content;
        }
    }
}

@mixin root-theme-dark {
    body.jl-theme--dark {
        @content;
    }
}