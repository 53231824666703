@import "~@ui/styles/variables";
@import "~@ui/styles/tools";

.login-block {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;

    & > a {
        color: #20836D;
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    &--fixed {
        position: fixed;
        background: #F6F7F8;
        z-index: 4;
    }

    &--scrolled {
        top: 60px;   
    }
}

@include media-tablet {
    .login-block {
        font-size: 16px;
        line-height: 30px;
        height: 45px;
    }

    .login-block--scrolled {
        top: 68px;
    }
}

@include media-mobile {
    .login-block {
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        justify-content: left;
        padding-left: 20px;
    }

    .login-block--scrolled {
        top: 50px;
    }
}
