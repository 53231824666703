@import '../../../styles/variables';

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--inverted {
        justify-content: revert;
    }
}

.label {
    font-size: $font-size--normal;
    line-height: 22px;
    letter-spacing: 0.3px;
    font-weight: 300;
    color: $color-title-active;
    margin-right: 16px;
}

.toggle {
    display: inline-flex;
    padding: 2px;
    align-items: center;
    width: 60px;
    min-width: 60px;
    height: 32px;
    border: 1px solid $color-grey-light;
    background: $color-input-background;
    border-radius: 16px;
    text-align: left;
    outline: none;
    cursor: pointer;

    &:not(.toggle--disabled) {
        &:hover {
            .toggle-knob {
                box-shadow: 0px 0px 8px rgba(191, 191, 191, 0.4);
            }
        }
    }
}

.toggle--active {
    background-color: $color-primary;
    border-color: $color-primary;
}

.toggle--small {
    width: 56px;
    min-width: 56px;
    height: 18px;
    padding: 0;
}

.toggle-knob {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 15px;
    width: 30px;
    height: 30px;
    background: #fff;

    transform: translateX(-2px);
    transition: all 0.315s ease-in-out;
}

.toggle-knob--small {
    width: 30px;
    height: 30px;
    transform: translateX(-1px);
    background-color: $color-off-white;
    border: 1px solid $color-grey--light;
}

.toggle-knob--active-bg {
    background: $color-off-white;
}

.toggle-knob--active {
    transform: translateX(26.2px);
}

.toggle-knob--active--small {
    transform: translateX(28px);
}

.toggle--disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.toggle--loading {
    pointer-events: none;
    cursor: default;
}