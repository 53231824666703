.jivo-chat-button {
    :global(.__jivoMobileButton) {
        transition: all .1s ease-in-out;
        transform: translateY(180px);
    }
}

.jivo-chat-button--active {
    :global(.__jivoMobileButton) {
        transform: translateY(0px);
    }
}