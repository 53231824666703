@import "~@ui/styles/tools";

@mixin medium-breakpoint {
  @media screen and (max-width: 1470px) {
    @content;
  }
}

.container {
  display: grid;
  gap: 24px;
  grid-template-areas:
  "calc chart"
  "calc chart"
  "calc chart"
  "calc apply"
  "sum apply";

  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);

  @include medium-breakpoint {
    grid-template-areas:
    "calc"
    "sum"
    "chart"
    "apply";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  @include media-mobile{
    grid-template-areas:
    "calc"
    "sum"
    "apply";
  }
}

.block {
  background-color: #F3F4F5 !important;
}

.calc {
  grid-area: calc;
}

.chart {
  grid-area: chart;
  padding: 16px;
  min-height: 338px;

  &::-webkit-scrollbar {
    display: none;
  }

  &>div {
    padding: 0 !important;
    height: 100% !important;
  }

  @include media-mobile {
    display: none;
  }
}

.apply {
  grid-area: apply;
  position: relative;
  overflow: hidden;
  min-height: 240px;
}

.sum {
  grid-area: sum;
}