@import "../../../styles/tools";

$font-size-button: $font-size--small;
$font-size-button--small: $font-size--xsmall;

@mixin styledButtonWithOutline($textColor, $backgroundColor, $outlineTextColor, $outlineBorderColor) {
    background: $backgroundColor;
    color: $textColor;

    &.button--outline {
        background: transparent;
        border-color: $outlineBorderColor;
        color: $outlineBorderColor;

        &:not(:disabled):not(.button--disabled) {
            &:hover, &:active {
                color: $outlineTextColor;
            }

            &:hover {
                border-color: darken($outlineBorderColor, 10%);
            }

            &:active {
                border-color: darken($outlineBorderColor, 15%);
            }
        }

        &:disabled, &.button--disabled {
            background: transparent;
            opacity: 0.3;
            color: $outlineBorderColor;
        }
    }

    &:not(:disabled):not(.button--disabled) {
        &:hover {
            background: darken($backgroundColor, 10%);
            color: $textColor;
        }

        &:active {
            background: darken($backgroundColor, 15%);
        }
    }

    &:disabled, &.button--disabled {
        background: $backgroundColor;
        color: $textColor;
        opacity: 0.3;
    }
}

@mixin styledButtonWithOutlineAlternative($textColor, $backgroundColor) {
    background: $backgroundColor;
    color: $textColor;

    &.button--outline {
        background: $backgroundColor;
        border-color: $textColor;
        color: $textColor;

        &:not(:disabled):not(.button--disabled) {
            &:active {
                color: $textColor;
                border-color: darken($textColor, 15%);
            }

            &:hover {
                border-color: darken($textColor, 10%);
            }
        }

        &:disabled, &.button--disabled {
            background: $backgroundColor;
            color: $textColor;
            opacity: 0.3;
        }
    }

    &:not(:disabled):not(.button--disabled) {
        &:hover {
            background: darken($backgroundColor, 10%);
            color: $textColor;
        }

        &:active {
            background: darken($backgroundColor, 15%);
        }
    }

    &:disabled, &.button--disabled {
        background: $backgroundColor;
        color: $textColor;
        opacity: 0.3;
    }
}

@mixin styledButtonGradientWithOutline($textColor, $backgroundColor) {
    background: $backgroundColor;
    color: $textColor;
    border: none;

    &.button--outline {
        background: $backgroundColor;
        border-color: $textColor;
        color: $textColor;

        &:hover, &:active {
            color: $textColor;
        }

        &:hover:not(:disabled):not(.button--disabled) {
            border-color: darken($textColor, 10%);
        }

        &:active {
            border-color: darken($textColor, 15%);
        }

        &:disabled {
            background: $backgroundColor;
            color: $textColor;
            opacity: 0.3;
        }
    }

    &:hover {
        background: $backgroundColor;
        opacity: 0.5;

    }

    &:disabled {
        background: $backgroundColor;
        color: $textColor;
        opacity: 0.3;
    }
}

@mixin darkStyledButtonWithOutline($textColor, $backgroundColor, $outlineTextColor, $outlineBorderColor) {
    background: $backgroundColor;
    color: $textColor;

    &.button--outline {
        background: transparent;
        border-color: $outlineBorderColor;
        color: $outlineBorderColor;

        &:hover, &:active {
            color: $outlineTextColor;
        }

        &:hover {
            border-color: lighten($outlineBorderColor, 10%);
        }

        &:active {
            border-color: lighten($outlineBorderColor, 15%);
        }

        &:disabled {
            background: transparent;
            color: $backgroundColor;
            opacity: 0.3;
        }
    }

    &:hover {
        background: lighten($backgroundColor, 10%);
    }

    &:active {
        background: lighten($backgroundColor, 15%);
    }

    &:disabled {
        background: $backgroundColor;
        color: $textColor;
        opacity: 0.3;
    }
}

@mixin styledButton($textColor, $backgroundColor) {
    @include styledButtonWithOutline($textColor, $backgroundColor, $textColor, $backgroundColor);
}

@mixin styledButtonAlternative($textColor, $backgroundColor) {
    @include styledButtonWithOutlineAlternative($textColor, $backgroundColor);
}

@mixin styledGradientButton($textColor, $backgroundColor) {
    @include styledButtonGradientWithOutline($textColor, $backgroundColor);
}

@mixin darkStyledButton($textColor, $backgroundColor) {
    @include darkStyledButtonWithOutline($textColor, $backgroundColor, $textColor, $backgroundColor);
}

.button-container {
    display: inline-block;
    border: 2px solid transparent;
    border-radius: $border-radius;
    padding: $focus-outline-distance;
    margin: -$focus-outline-distance;
    user-select: none;

    &:not(:last-child) {
        margin-right: 20px - $focus-outline-distance;
    }

    &.button-container--block {
        display: block;
        width: 100%;
    }

    &.button-container--focus {
        border-color: $focus-outline-color;
        position: relative;
        z-index: 1;
    }

    &.button-container--small {
        &:not(:last-child):not(.button-container--no-spacing) {
            margin-right: 12px - $focus-outline-distance;
        }
    }

    &.button-container--xsmall {
        &:not(:last-child) {
            margin-right: 8px - $focus-outline-distance;
        }
    }

    &.button-container--thin {
        padding: 0;
    }

    &.button-container--no-spacing {
        margin-right: -$focus-outline-distance;;
    }
}

.button__icon {
    width: 14px;
    height: 14px;
    transform: translateY(3px);
}

.button__icon + .button__text {
    margin-left: 10px;
}

.button__text + .button__icon {
    margin-left: 10px;
}

.button__content--caret {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .button__text {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.icon--caret {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 12px;
    transition: all 0.185s ease-in-out;

    img {
        width: 16px;
        height: auto;
    }
}


.button {
    -webkit-appearance: none;
    background: transparent;
    border: 1px solid transparent;
    border-radius: $border-radius;
    min-width: 200px;
    font-size: $font-size-button;
    line-height: $font-size-button * 1.5;
    padding: 11px 25px 10px 25px;
    text-align: center;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;

    transition: all 0.185s ease-in-out;

    &:hover {
        text-decoration: none !important;

        &.button--success:not(:disabled) {
            .button__right-content {
                background-color: darken($color-success-dark, 10%);
            }
        }

        &.button--danger:not(:disabled) {
            .button__right-content {
                background-color: darken($color-error-dark, 10%);
            }
        }
    }

    &:active {
        text-decoration: none;
        
        &.button--success:not(:disabled) {
            .button__right-content {
                background-color: darken($color-success-dark, 15%);
            }
        }

        &.button--danger:not(:disabled) {
            .button__right-content {
                background-color: darken($color-error-dark, 15%);
            }
        }
    }

    @media screen and (max-width: 1500px) {
        & {
            width: 100%;
            min-width: 130px;
        }
    }

    @include media-mobile {
        & {
            width: 100%;
            min-width: auto;
        }
    }

    &.button--align--left {
        text-align: left;
    }

    &.button--loading {
        pointer-events: none;
    
        .button__loading-indicator {
            display: inline-flex;
            align-content: center;
            vertical-align: middle;
            flex-direction: column;
            justify-content: center;
            margin-top: -23px;
            margin-bottom: -20px;
            margin-left: -28px;

            & + .button__text {
                margin-left: 5px;
            }
        }

        .button__text + .button__loading-indicator {
            margin-left: 8px;
        }

        .button__right-content {
            margin-left: 21px;
        }
    }


        &.button--plain {
            background-color: transparent;
            color: $color-primary;

            &:hover {
                color: darken($color-primary, 10%);
            }

            &:active {
                color: darken($color-primary, 15%);
            }

            &:disabled {
                color: $color-primary; 
            }
        }

        &.button--default {
            @include styledButton($color-off-white, $color-primary);

            @include theme-dark {
                @include styledButton(#FFF, #20836D);
            }
        }
        
        &.button--primary {
            @include darkStyledButton($color-off-white, $color-title-active);
        }

        &.button--warning {
            @include styledButton($color-off-white, $color-warning);
        }
    
        &.button--danger {
            @include styledButton($color-off-white, $color-error);

            .button__right-content {
                background-color: $color-error-dark;
                transition: background-color 0.185s ease-in-out;
            }
        }

        &.button--alternative {
            @include styledButtonAlternative($color-primary, $color-primary-opaque);
        }

        &.button--success {
            @include styledButton($color-off-white, $color-success);

            .button__right-content {
                background-color: $color-success-dark;
                transition: background-color 0.185s ease-in-out;
            }
        }

        &.button--grey_dark {
            @include styledButton($color-off-white, $color-grey-dark);
        }

        &.button--secondary {
            @include styledButtonWithOutline(#262726, #DCDBDC, $color-title-active, #ADB1B6);
        }

        &.button--white {
            @include styledButton($color-title-active, #FFFFFF);
        }

        &.button--light {
            @include styledButton($color-title-active, #F6F7F8);
        }
        
        &.button--ultra {
            @include styledButton($color-off-white, $color-ultra);
        }

        /**Gradients */

        &.button--gradient_main {
            @include styledGradientButton($color-off-white, $color-gradient-main);
        }

        &.button--gradient_secondary {
            @include styledGradientButton($color-off-white, $color-gradient-secondary);
        }

        &.button--gradient_accent {
            @include styledGradientButton($color-off-white, $color-gradient-accent);
        }

        &.button--gradient_pin {
            @include styledGradientButton($color-off-white, $color-gradient-pin);
            color: #616263;
            border-color: #1F3E38;
        }

        &.button--gradient_pin_delete {
            @include styledGradientButton($color-off-white, $color-gradient-pin-delete);
        }

        &.button--gradient_pin_face_id {
            @include styledGradientButton($color-off-white, $color-gradient-pin-face-id);
        }

    &.button--disabled {
        opacity: 0.5;
        cursor: default;
    }

    &.button--stretched {
        font-size: 16px;
        line-height: 22.4px;
        padding: 13px 32px;

        .button__loading-indicator {
            margin-left: -40px;
        }

        .button__icon {
            width: 24px;
            height: 24px;
            margin-top: -24px;
            transform: translateY(5px);
        }

        .button__right-content {
            height: 60px;
            padding-top: 19px;
            padding-left: 30px;
            padding-right: 30px;
            margin-right: -25px;
            border-radius: 0 12px 12px 0;
            font-size: 18px;
            margin-top: -19px;
        }
    }

    &.button--big {
        font-size: 18px;
        line-height: 20px;
        padding: 18px 24px 20px;
        max-width: auto;
        min-width: 130px;

        .button__loading-indicator {
            margin-left: -40px;
        }

        .button__icon {
            width: 24px;
            height: 24px;
            margin-top: -24px;
            transform: translateY(5px);
        }

        .button__right-content {
            height: 60px;
            padding-top: 19px;
            padding-left: 30px;
            padding-right: 30px;
            margin-right: -25px;
            border-radius: 0 12px 12px 0;
            font-size: 18px;
            margin-top: -19px;
        }
    }

    &.button--small {
        border-width: 1px;
        min-width: 130px;
        font-size: $font-size-button--small;
        padding: 8px 15px 7px 15px;

        .button__loading-indicator {
            margin-left: -10px;
        }

        .button__loading-indicator + .button__text {
            margin-left: 5px;
        }
    }

    &.button--xsmall {
        border-width: 1px;
        min-width: 34px;
        font-size: $font-size-button--small;
        padding: 8px 15px 7px 15px;

        .icon--caret img {
            width: 12px;
        }

        .button__loading-indicator {
            margin-left: -16px;
        }
    }

    &.button--small, &.button--xsmall {
        .button__right-content {
            height: 38px;
            padding-top: 8px;
            padding-left: 20px;
            padding-right: 20px;
            margin-right: -16px;
            border-radius: 0 12px 12px 0;
            font-size: 14px;
            margin-top: -9px;
        }
    }

    &.button--thin {
        border-width: 1px;
        min-width: 34px;
        font-size: $font-size-button--small;
        padding: 2px 7px 1px 7px;
        border-radius: 7px;

        &.button--loading {
            .button__text {
                display: none;
            }
        }

        .button__loading-indicator {
            margin-left: 0;
        }

        .button__loading-indicator + .button__text {
            margin-left: 3px;
        }

        .button__right-content {
            height: 26px;
            padding-top: 3px;
            padding-left: 15px;
            padding-right: 15px;
            margin-right: -8px;
            border-radius: 0 7px 7px 0;
            font-size: 12px;
            margin-top: -3px;
        }
    }

    &.button--caret {
        border-color: $color-input-background;
        color: $color-title-active;
        min-width: 80px;
        width: 100%;

        &:hover {
            background-color: transparent;
        }
    }

    .button__right-content {
        display: inline-block;
        margin-left: 26px;
        margin-right: -26px;
        margin-top: -12px;
        margin-bottom: -100%;
        padding-top: 11px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 0 12px 12px 0;
        color: white;
        font-weight: 500;
        font-size: 16px;
        pointer-events: none;
        height: 44px;
        float: right;
        background-color: rgba(0, 0, 0, 0.2);
        text-align: center;
    }
}