@import "~@ui/styles/tools";

.wrapper {
    max-width: 472px;
}

.icon-wrapper {
    position: relative;
    background: linear-gradient(129.17deg, #1F7A66 2.27%, #274B43 102.78%);
    border-radius: 24px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    min-height: 254px;

    &--default {
        background: linear-gradient(179.27deg, #D5DADC -1.66%, #919394 49.15%) !important;
        filter: grayscale(2);
    }
}

.icon {
    width: 152px;
    height: 176px;
    position: relative;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
    line-height: 31.6px;
    letter-spacing: 0.3px;
}

.text {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.36px;
}

.buttons {
    position: relative;
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon-button {
    position: relative;
    width: 30px;
    height: 30px;
    padding: 8px;
    border-radius: 50%;
    background: #F6F7F8;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        transform: translate(0px) scale(0);
    }

    &:hover {
        &::before {
            transform: translate(0px) scale(1) !important;
        }
    }
}

.share-icon {
    padding: 12px;
    border-radius: 50%;
    background: #e6e6e6;
}

.shared-buttons {
    position: absolute;
    bottom: 58px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.shared-buttons {
    opacity: 1;
    transition: 0.5s ease;
    pointer-events: auto;

    &--hide {
        opacity: 0;
        transition: 0.5s ease;
        pointer-events: none;
    }
}

@include media-mobile {
    .modal-container {
        padding: 0;
        height: 98vh;
    }

    .modal-header {
        height: 0;
    }

    .icon-wrapper {
        position: relative;
        z-index: -1;
        border-radius: 0;
        padding: 20px;
        margin-bottom: 0;
        height: 340px;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 20px;
            bottom: 0;
            background: #fff;
            border-radius: 12px 12px 0px 0px;
        }
    }

    .body {
        padding: 0 12px;
        height: calc(100% - 340px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .icon {
        width: 140px;
        height: 164px;
    }
    
    .title {
        margin-bottom: 15px;
        font-size: 20px;
        line-height: 28px;
    }
    
    .text {
        font-size: 16px;
        line-height: 24px;
    }
}
