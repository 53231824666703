.container {
    padding: 0;
    width: 100%;
    
}

.frame {
    width: 100%;
    min-height: 400px;
    border: none;
    border-radius: 10px;
}

.title {
    display: flex;
    flex-direction: column;
    gap: 8px;
}