@import "../../../styles/tools";

.button {
    display: inline-block;
    padding: 8px 12px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    text-align: center;
    width: 24px;
    box-sizing: content-box;

    &:focus {
        outline: $focus-outline-color;
    }

    &::before {
        display: block;
        content: ' ';
        width: 32px;
        height: 32px;
        position: relative;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 50%;
        margin-bottom: -32px;
        z-index: 0;

        transform: translate(-4px, -6px) scale(0);
        transition: transform 0.168s ease-in-out;
    }

    &.button--with-background {

        &::before {
            background: rgba(32, 131, 109, 0.15);

            transform: translate(-4px, -6px) scale(1);
            transition: all 0.168s ease-in-out;
        }

        &.button--loading {
            &::before {
                transform: translate(-4px, -8px) scale(1);
            }
        }

        &:not(.button--disabled):not(.button--loading) {
            &:hover {
                &::before {
                    background: rgba(32, 131, 109, 0.2);
                }
            }
            &:active {
                &::before {
                    background: rgba(32, 131, 109, 0.25);
                }
            }
        }
    }

    &:not(.button--disabled):not(.button--loading) {
        &:hover {
            &::before {
                transform: translate(-4px, -6px) scale(1);
            }
        }
    }

    & + .button {
        margin-left: -12px;
    }
}

.button--loading {
    cursor: default;
}

.button--disabled {
    cursor: default;
    opacity: 0.5;
}

.button__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button__icon {
    width: 20px;
    height: 20px;
}

.button--margin-left {
    margin-left: 5px;
}

.button--margin-right {
    margin-right: 5px;
}


.button--small {
    padding: 4px 2px;

    .button__icon {
        width: 14px;
        height: 14px;
    }

    &::before {
        transform: translate(-4px, -8px) scale(0);
    }

    &.button--with-background {

        &::before {
            background: rgba(32, 131, 109, 0.15);

            transform: translate(-4px, -8px) scale(1);
            transition: all 0.168s ease-in-out;
        }

        &.button--loading {
            &::before {
                transform: translate(-4px, -7px) scale(1);
            }
        }

        &:not(.button--disabled):not(.button--loading) {
            &:hover {
                &::before {
                    background: rgba(32, 131, 109, 0.2);
                }
            }
            &:active {
                &::before {
                    background: rgba(32, 131, 109, 0.25);
                }
            }
        }
    }

    &:not(.button--disabled):not(.button--loading) {
        &:hover {
            &::before {
                transform: translate(-4px, -8px) scale(1);
            }
        }
    }
}

.button--large {
    .button__icon {
        width: 24px;
        height: 24px;
    }

    &::before {
        width: 40px;
        height: 40px;
        margin-bottom: -40px;
        transform: translate(-8px, -8px) scale(0);
    }

    &.button--with-background {

        &::before {
            background: rgba(32, 131, 109, 0.15);

            transform: translate(-8px, -8px) scale(1);
            transition: all 0.168s ease-in-out;
        }

        &.button--loading {
            &::before {
                transform: translate(-8px, -6px) scale(1);
            }
        }

        &:not(.button--disabled):not(.button--loading) {
            &:hover {
                &::before {
                    background: rgba(32, 131, 109, 0.2);
                }
            }
            &:active {
                &::before {
                    background: rgba(32, 131, 109, 0.25);
                }
            }
        }
    }

    &:not(.button--disabled):not(.button--loading) {
        &:hover {
            &::before {
                transform: translate(-8px, -8px) scale(1);
            }
        }
    }
}

.button--extra-large {
    .button__icon {
        width: 26px;
        height: 26px;
    }

    &::before {
        width: 46px;
        height: 46px;
        margin-bottom: -46px;

        transform: translate(-11px, -10px) scale(0);
    }

    &.button--with-background {

        &::before {
            background: rgba(32, 131, 109, 0.15);

            transform: translate(-11px, -10px) scale(1);
            transition: all 0.168s ease-in-out;
        }

        &.button--loading {
            &::before {
                transform: translate(-11px, -9px) scale(1);
            }
        }

        &:not(.button--disabled):not(.button--loading) {
            &:hover {
                &::before {
                    background: rgba(32, 131, 109, 0.2);
                    transform: translate(-11px, -10px) scale(1);
                }
            }
            &:active {
                &::before {
                    background: rgba(32, 131, 109, 0.25);
                }
            }
        }
    }

    &:not(.button--disabled):not(.button--loading) {
        &:hover {
            &::before {
                transform: translate(-11px, -10px) scale(1);
            }
        }
    }
}