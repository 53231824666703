@import "../../../styles/tools";

.container--margin {
    margin-top: 12px;
}

.label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:not(.label-wrapper--disabled) {
        cursor: pointer;
    }
}

.label-wrapper--vertical {
    align-items: flex-start;
    flex-direction: column;
}

.label {
    flex: 1;
    padding-right: 10px;
}

.label__text {
    font-size: $font-size--small;
    line-height: $font-size--small;
    font-weight: 300;
}

.label__error {
    font-size: $font-size--small;
    line-height: $font-size--small;
    margin-top: 3px;
    color: $color-error;
}

.input-wrapper--full-width {
    width: 100%;
}

input[type].input {
    width: 96px;
    min-width: 96px;
    flex: 0;
    border-radius: 5px;
    background: $color-input-background;
    color: $color-title-active;
    padding: 7px 12px;
    border: solid 1px transparent;
    font-size: $font-size--small;
    line-height: $font-size--small;
    font-weight: 300;
    text-align: left;

    &.input--no-label {
        width: 100%;
    }

    &:not(.input--disabled) {
        &:focus {
            border-color: $color-primary;
        }

        &.input--error {
            border-color: $color-error;
            background: rgba($color-error, 0.2);
        }
    }

    &.input--disabled {
        background: #f8f8f8;
        color: #c7c7c7;
    }
}

.input__postfix {
    display: inline-block;
    height: 42px;
    width: 26px;
    line-height: 42px;
    font-size: $font-size--small;
    color: $color-grey--dark;
    font-weight: 500;
    margin-left: -26px;
    transform: translate(-5px, 0);
    text-align: end;
}

.container--size-large {
    input[type].input {
        width: 100%;
        font-size: 20px;
        line-height: 1em;
        padding-right: 40px;
    }

    .input__postfix {
        font-size: 20px;
        line-height: 1em;
        font-weight: 400;
        width: 24px;
        margin-left: -24px;
    }
}

@include media-mobile {
    .label {
        font-size: 12px;
    }

    input[type].input {
        min-width: 100px;
        font-size: 12px;
        padding: 12px 8px;
    }
}