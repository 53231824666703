@import "~@ui/styles/tools";

.wrapper {
    border: 1px solid #1E20211A;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: #fff;
    height: 305px;
    transition: all 0.5s ease;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 10px 28px 0px rgba(136, 136, 136, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
        transition: all 0.5s ease;
    }

    &--default {
        color: #BCBCBC;

        & .image {
            background: transparent;
            border: 1.16px solid #1E20211A;

            & img {
                filter: grayscale(2);
            }
        }
    }
}

.image {
    position: relative;
    width: 74px;
    height: 78px;
    background: linear-gradient(121.55deg, #1F7A66 10.28%, #274B43 103.12%);
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        transform: scaleX(0.55) scaleY(0.6);
    }
}

.title {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.3px;
    text-align: center;
    min-height: 60px;
}

.text {
    font-size: 16px;
    font-weight: 300;
    line-height: 22.4px;
    letter-spacing: 0.3px;
    text-align: center;
    max-width: 80%;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: normal;
}

.investors_block {
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: 0.3px;
    text-align: center;
    color: #A2A8AD;
}

@include media-mobile {

    .wrapper {
        padding: 15px 5px;
        gap: 5px;
        height: 100%;
    }

    .title {
        font-size: 16px;
        line-height: 23px;
        min-height: 46px;
    }

    .text {
        font-size: 14px;
        line-height: 19.6px;
        height: 42px;
    }

    .investors_block {
        font-size: 14px;
        line-height: 22.4px;
    }

    .image {
        width: 60px;
        height: 60px;

        > img {
            height: 42px;
            width: 40px;
        }
    }
}