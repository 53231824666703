@import "~@ui/styles/tools";

@mixin breakpoint-1450 {
  @media screen and (max-width: 1450px) {
    @content;
  }
}

.social-media {
  background-color: white;
  border-radius: 24px;
  padding: 28px;
  width: fit-content;

  align-items: center;
  gap: 10px;
  height: fit-content;

  & > * {
    margin: 0 !important;
  }

  &--vertical {
    flex-direction: column;
  }

  &--horizontal {
    flex-direction: row;
  }

  @include breakpoint-1450 {
    background: transparent;
    padding: 0;
  }
}

.button-container {
  width: 50px;
  height: 50px;
  padding: 15px;

  border-radius: 100%;
  background: #F6F7F8;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.168s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }

  @include breakpoint-1450 {
    width: 36px;
    height: 36px;
    background: white;
    padding: 10px;
  }
}

.button-icon {
  position: relative;
  width: 100%;
  height: 100%;

  &--offset {
    margin-left: -3px;
  }
}