@import "~@ui/styles/tools";

.container {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0 -10px;

    @include media-mobile {
        padding: 0;
        margin: 0px -33px;
        transform: translateX(-25px);
    }
}

.item-wrapper {
    position: relative;
    margin: 0 10px;
}