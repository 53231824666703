@import "~@ui/styles/tools";

.title {
  font-size: 40px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0;
  margin-bottom: 32px;

  @include media-mobile {
    font-size: 20px;
    line-height: 22px;
  }
}

.form-label {
  opacity: 0.5;

  @include media-mobile {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0;
  }
}

.range-container {
  margin-bottom: 30px;
}

.range-line {
  background: #d9d9d9;
}

.range-label {
  font-size: $font-size--large;
  font-weight: 400;
  color: $color-title-active;

  @include media-mobile {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
  }
}

.selectors {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 28px;
}

.switcher-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.switcher-label {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0;
  max-width: 65%;

  @include media-tablet {
    max-width: 100%;
  }

  @include media-mobile {
    font-size: 12px;
    line-height: 14px;
  }
}