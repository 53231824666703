@import "../../styles/tools";

.image {
    max-width: 370px;
    width: 100%;
}

.container {
    padding: 10px;
}

.buttons-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.button {
    width: 160px;
}

@include media-mobile {
    .buttons-list {
        gap: 10px;

        & > * {
            flex: 1;
        }
    }

    .button {
        width: 100%;
    }
}