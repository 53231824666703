@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

.popper {
    height: 100%;
    border: 1px solid rgba(30, 32, 33, 0.1);
    border-radius: 12px;
}

.director-card {
    height: 100%;
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: max-content 1fr;

    grid-template-areas:
        'image'
        'description';

    row-gap: 16px;
    padding: 12px;
    border-radius: 12px;
    border: 1px solid rgba(30, 32, 33, 0.1);
}

.photo {
    grid-area: image;
    width: 188px;
    height: 188px;
    border-radius: 12px;

    position: relative;
}

.description {
    grid-area: description;
    display: flex;
    flex-direction: column;
}

.name {
    color: #000000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;

    margin: 0 0 8px;
}

.position {
    color: #585a5f;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;

    margin: 0 0 8px;
}

.show-more {
    color: $color-primary;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin: auto 0 0;
    padding: 0;
    background-color: transparent;
    border: none;
    width: fit-content;
    cursor: pointer;
}

.image {
    border-radius: 12px;
}

@include media-tablet-portrait {
    .photo {
        height: 120px;
        width: 120px;
    }

    .name,
    .position,
    .show-more {
        font-size: 14px;
        line-height: 18px;
    }
}

@include media-mobile {
    .director-card {
        row-gap: 12px;
    }

    .photo {
        height: 162px;
        width: 162px;
    }


}
