@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

.dropdown-wrapper {
    display: none;
}

.filter {
    display: flex;
    column-gap: 12px;
}

@include media-tablet-portrait {
    .dropdown-wrapper {
        display: block;

        height: 54px;
    }

    .filter {
        display: none;
    }
}