@import "~@ui/styles/tools";

@mixin medium-breakpoint {
  @media screen and (max-width: 1470px) {
    @content;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: 28px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0;
  z-index: 1;
  position: relative;

  @include media-mobile {
    font-size: 20px;
  }
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  color: #8C8C8C;
  max-width: 40%;
  margin-top: 16px;
  z-index: 1;
  position: relative;
  margin-bottom: 30px;

  @include media-tablet {
    max-width: 100%;
    margin-bottom: 0;
  }

  @include media-mobile {
    max-width: 100%;
    font-size: 16px;
  }
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5px;
  right: -150px;
  pointer-events: none;
  z-index: 0;

  @include medium-breakpoint {
    right: -35%;
  }

  @include media-mobile {
    width: 70%;
    right: 0;
    top: -40px;
  }
}

.apply-button-container {
  @include medium-breakpoint {
    width: 400px;
  }

  @include media-tablet {
    width: 300px;
  }

  @include media-mobile {
    width: 100%;
  }
}