@import "~@ui/styles/tools";

@mixin small-breakpoint {
  @media screen and (max-width: 650px) {
    @content;
  }
}

@mixin extra-small-breakpoint {
  @media screen and (max-width: 390px) {
    @content;
  }
}

.container {
  display: flex;
  height: 100%;
  justify-content: space-between;

  @include small-breakpoint {
    gap: 20px;
  }

  @include extra-small-breakpoint {
    gap: 6px;
  }
}

.statement {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 9px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.5);

  @include small-breakpoint {
    font-size: 12px;
    line-height: 14px;
  }

  @include extra-small-breakpoint {
    font-size: 10px;
    line-height: 12px;;
  }

}

.value {
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0;
  white-space: nowrap;

  &.main {
    color: #20836D;
  }

  & .light {
    opacity: 0.5;
  }

  @include small-breakpoint {
    font-size: 16px;
    line-height: 24px;
  }

  @include extra-small-breakpoint {
    font-size: 13px;
    line-height: 20px;;
  }
}

.separator {
  height: 100%;
  width: 1px;
  margin: 0 32px;
  background: #DEDEDE;

  @include media-tablet-portrait {
    margin: 0 16px;
  }

  @include small-breakpoint {
    display: none;
  }
}