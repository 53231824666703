@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.block-header {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
    column-gap: 10px;

    margin-bottom: 40px;
}

.block-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.filter {
    grid-column: span 2;
}

@include small-breakpoint {
    .block-body {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include media-tablet {
    .block-header {
        grid-template-columns: max-content;

        row-gap: 28px;
        margin-bottom: 36px;
    }

    .filter {
        grid-column: span 1;
    }
}

@include media-tablet-portrait {
    .block-header {
        grid-template-columns: 1fr;
    }

    .block-body {
        grid-template-columns: 1fr;
    }
}

@include media-mobile {
    .block-header {
        margin-top: 15px;
    }
}

