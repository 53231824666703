.buttons-list {
  &>span {
    margin: 0;
  }
  
  margin: 40px -5px 0;
}

.container {
  position: relative;
  overflow: hidden;
}