@import "~@ui/styles/tools";

.container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;
    text-align: center;
    background: #F6F7F8;
    padding: 25px;
    border-radius: 24px;
    height: 344px;
    overflow: hidden;
    transition: 0.5s ease;

    @media (max-width: 1600px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-tablet-portrait {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-mobile {
        padding: 12px;
        height: 560px;
    }

    &--more {
        height: auto;
    }
}

.container--small {
    @include media-mobile {
        padding: 12px;
        height: 260px;
    }
}

.wrapper {
    border: 1px solid #1E20211A;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: #fff;
    height: 305px;
}

.default-wrapper {
    color: #BCBCBC;

    & .image {
        background: transparent;
        border: 1.16px solid #1E20211A;

        & img {
            filter: grayscale(2);
        }
    }
}

.image {
    position: relative;
    width: 74px;
    height: 78px;
    background: linear-gradient(121.55deg, #1F7A66 10.28%, #274B43 103.12%);
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        transform: scaleX(0.55) scaleY(0.6);
    }
}

.title {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.3px;
    text-align: center;
    min-height: 60px;
}

.text {
    font-size: 16px;
    font-weight: 300;
    line-height: 22.4px;
    letter-spacing: 0.3px;
    text-align: center;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: normal;
}

.investors_block {
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: 0.3px;
    text-align: center;
    color: #A2A8AD;
    min-height: 25px;
}

.more {
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    color: #20836D;
    text-align: center;
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
}

.more-arrow {
    transition: 0.5s ease;

    &--rotate {
        transform: rotate(180deg);
        transition: 0.5s ease;
    }
}

@include media-mobile {

    .wrapper {
        padding: 15px 5px;
        gap: 5px;
        height: 100%;
    }

    .title {
        font-size: 16px;
        line-height: 23px;
        min-height: 46px;
    }

    .text {
        font-size: 14px;
        line-height: 19.6px;
        height: 42px;
    }

    .investors_block {
        font-size: 14px;
        line-height: 22.4px;
    }

    .more {
        font-size: 16px;
        line-height: 19.09px;
        margin-top: 20px;
    }

    .image {
        width: 60px;
        height: 60px;

        > img {
            height: 42px;
            width: 40px;
        }
    }
}